.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: black;
  border-bottom: 5px solid;
  background-color: transparent;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

.nav-pills .nav-link {
  border-image-slice: 1;
  border-width: 1px;
  color: black;
}

/** Common CSS */


.hamzateamhead 
{
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

@media screen and (min-width: 601px) {
  .hamzamainhead 
  {
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 900;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#CC0033, #3300CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


@media screen and (max-width: 600px) {
  .hamzamainhead 
  {
    font-family: "Poppins", Sans-serif;
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#CC0033, #3300CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}




.bg-hamza
{
  background: linear-gradient(45deg, #CC0033, #3300CC) !important;
}


.hamzabtn
{
  background: linear-gradient(45deg, #CC0033, #3300CC) !important;
  color: white;
  padding: 7px 25px 7px 25px;
  border-radius: 16px;
  border-style: none;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (min-width: 601px) {
  .hamzaaddbtn
  {
    background: none;
    border: none;
    font-size: 35px;
  }
}

@media screen and (max-width: 600px) {
  .hamzaaddbtn
  {
    background: none;
    border: none;
    font-size: 20px;
  }
}

/* For Query Builder */
.ruleGroup-addGroup
{
  background: linear-gradient(45deg, #CC0033, #3300CC) !important;
  color: white;
  padding: 7px 25px 7px 25px;
  border-radius: 16px;
  border-style: none;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}


.ruleGroup-addRule
{
  background: linear-gradient(45deg, #CC0033, #3300CC) !important;
  color: white;
  padding: 7px 25px 7px 25px;
  border-radius: 16px;
  border-style: none;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.rule-fields
{
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.rule-operators
{
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.rule-value
{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.rule-remove
{
  padding: 0.375rem 0.75rem;
  color: white;
  background-color: red;
  border-radius: 50%;
  border-style: none;
}

.ruleGroup-remove
{
  padding: 0.375rem 0.75rem;
  color: white;
  background-color: red;
  border-radius: 50%;
  border-style: none;
}


/** Fixtures Page CSS */
.logosize
{
  width: 40px;
  height: 40px;
}


.hamzafixturehead
{
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#3300CC, #CC0033);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.scorehead
{
  font-size: 40px;
}

.vdbtn
{
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: -webkit-linear-gradient(#3300CC, #CC0033);
}
.vdbtn a
{
  text-decoration: none;
  color: white;
}

.teamlose
{
  background-color: red;
}


@media screen and (min-width: 601px) {
  .hamzanavicons
  {
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    margin-left: 10px;
    color: white;
    text-transform: uppercase;
    border-radius: 15px;
    text-align: center;
    font-size: 15px;
  }

  .hamzanavicons i
  {
    font-size: 25px;
  }

  .hamzafixturematchhead
  {
    font-family: "Poppins", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 10px;
  }

}


@media screen and (max-width: 600px) {
  .hamzanavicons
  {
    padding: 2px 5px 2px 5px;
    margin-right: 2.5px;
    margin-left: 2.5px;
    color: white;
    text-transform: uppercase;
    border-radius: 15px;
    text-align: center;
    font-size: 10px;
  }

  .hamzanavicons i
  {
    font-size: 20px;
  }

  .hamzafixturematchhead
  {
    font-family: "Poppins", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 10px;
  }

}


/** Stats Page CSS */

.logosizeformd
{
  width: 75px;
  height: 75px;
}

.statsnav
{
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #CBC3E3;
}

.statstabbutton
{
  font-weight: 900;
  text-decoration: none;
  color: black;
}


.matchnamestats
{
  background-color: cadetblue;
  color: white;
  padding: 5px 20px 5px 20px;
  border-radius: 15px;
}


.leaguenamestats
{
  padding: 5px 20px 5px 20px;
  border-radius: 15px;
  
}



.blink_me {
  animation: blinker .7s linear infinite;
}

@keyframes blinker {  
  50% { opacity: .4; }
}

.numbershead
{
  font-family: 'Montserrat';
  font-size: 25px;
  font-weight: 900;
}


.headinginmd
{
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 600;
}

.subheadinginmdsmall
{
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 600;
}

.subheadinginmd
{
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#3300CC, #CC0033);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hamzatablehead
{
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1px;
  background-color: #CBC3E3;
}


.hamzatablehead th
{
  padding: 10px 5px 10px 5px;
}

.hamzatablebody
{
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 900;
}

@media screen and (min-width: 601px) {
  .hamzaiconsize
  {
    font-size: 45px;
    padding-bottom: 20px;
  }
}


.stmainh
{
  font-size: 18px;
  font-weight: 800;
}

.stsubh
{
  font-size: 16px;
  font-weight: 800;
}



/* NEW TABLE CODE */

.hamzasummarytablehead
{
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: underline;
  text-transform: uppercase;
}

.hamzasummarytableheadtabular
{
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #f9e8fc;
  color: black;
}

.hamzasummarytablehead2
{
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-decoration: underline;
  text-transform: uppercase;
}

.hamzacardrowmiddle , .hamzacardrowtop ,.hamzacardrowbot 
{
  background-color: #eaeefa;
}

.hamzacardrowtop td
{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.hamzacardrowmiddle td
{
  padding: 20px;
}

.hamzacardrowbot td:first-child 
{
  border-bottom-left-radius: 20px;
}
.hamzacardrowbot td:last-child 
{
  border-bottom-right-radius: 20px;
}


.hamzaliveicons
{
  font-size: 40px;
  color: purple;
}




/* STATS SUMMARY PAGE */
.teamlogosumstats
{
  width: 40px;
  height: 40px;
}

.summarystatsrh
{
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.numbersheadsummary
{
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 900;
}



.purpletext
{
  color: purple;
}

.datebox:hover
{
  background: -webkit-linear-gradient(#3300CC, #CC0033);
  color: white;
}


.headingfilter 
{
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 900;
  text-transform: uppercase;
  vertical-align: middle;
  background: -webkit-linear-gradient(#CC0033, #3300CC);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.statsnumberorb
{
  font-family: 'Kameron';
  font-size: 14px;
  font-weight: 600;
}

.statsnumberorbp
{
  font-family: 'Kameron';
  font-size: 14px;
  font-weight: 600;
}

.sorticon
{
  font-size: 11px;
}

.sorticon:hover
{
  font-size: 16px;
  color: #61dafb;
}

.question {
  width: 500px;
  height: 500px;
}

.question-container {
  width: 100%;
  height: 100%;
}


/* LOGIN CSS */
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.form__field {
  font-family: "Poppins", Sans-serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.1rem;
  color: black;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
    user-select: none;
    pointer-events: none;
  }
}

.form__label {
  font-family: "Poppins", Sans-serif;
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #743ad5;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #743ad5, #d53a9d);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}


.hamzaformlabel {
  font-family: "Poppins", Sans-serif;
  font-size: 1rem;
  font-weight: 600;
}


.filterbadgestext
{
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
}


.alert-icon
{
  font-size: 30px;
  color: purple;
}

.favourite-icon
{
  font-size: 24px;
  color: gold;
}

